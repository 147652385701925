@import "Charts/styles-index";
@import "TablePanel";
@import "CounterPanel";
@import "PanelPlaceholder";
@import "ErrorPanel";
@import "PanelDescription";

.panel-title-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .info-action {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
  }
}

.panel-title-container.top-border {
  border-top: .1rem solid white;
  padding-top: .5rem;
  margin-top: .5rem;
}

.panel-description {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  color: var(--cds-text-secondary, #525252);

  ol {
    padding-left: 1.25em;
    list-style: decimal;
  }

  ul {
    padding-left: 1.25em;
    list-style: disc;
  }
}

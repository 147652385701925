.data-ingress {
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 100vw;

  .status-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem 0;
  }

  .section-import {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .section-display {
    max-height: 60vh;
    overflow: auto;
  }

  .error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .no-content-wrapper {
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .no-content-wrapper-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }
}
@use "@carbon/styles";
@import "@carbon/charts/styles/styles.scss";

.chart-holder {

  @include styles.theme(styles.$g100);
  @media screen and (max-width: 830px) {
    height: 50rem !important;
  }
  color: #000000 !important;
  //background: var(--cds-background) !important;
  background: #ffffff !important;
  
  --cds-icon-primary: black !important;
  --cds-text-primary: black !important;
  --cds-text-secondary: black !important;

  .bx--cc--chart-wrapper {
    background: #ffffff !important;
  }

  
  p {
    color: #000000 !important;
  }

  .chart-grid-backdrop .stroked {
    stroke: #ffffff !important;
    fill: #ffffff !important;

    .tick {
      line {
        stroke: #ffffff !important;
      }

      text {
        fill: #000000 !important;
        color: #000000 !important;
      }
    }
  }

  .chart-grid-backdrop {
    fill: #ffffff !important;
  }

  .axis {
    .tick {
      text {
        fill: #000000 !important;
      }
    }
  }

  .axis-title {
    color: #000000 !important;
  }

  .bx--cc--tooltip {
    background: #000000 !important;
  }
}
.phase-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .phase {
    padding: 1rem;
    cursor: pointer;
    max-width: 5rem;
    width: 5rem;

    .cds--popover {
      z-index: 2;

      .key {
        text-transform: capitalize;
      }

      .phaseDescriptionItem {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: .2rem;
        gap: 1rem;
      }

      .innerPhaseDescription {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: .2rem;

        .innerItems {
          padding-left: .5rem;
          display: flex;
          flex-direction: column;
          align-items: baseline;
        }
      }
    }

    @media (pointer: coarse) {
      .cds--popover {
        display: none;
      }
    }
  }

  .phase-separator {
    display: flex;
    align-items: center;
  }

}

.notifications-page-container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.no-notifications{
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}

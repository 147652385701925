@import "Panels/styles-index";
@import "DataProcessSection/styles-index";
@import "./Login/index";
@import "./Modal/index";
@import "./Notification/index";
@import "./Profile/index";
@import "./CronPicker/index";
@import "./DataIngressSection/styles-index";

.button-loading{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
}
.no-data-source {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  min-height: 10rem;

  h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
  }
}
:root .cds--cc--chart-wrapper .stroke-1-1-1{
    stroke: #884502 !important;
}

:root .cds--cc--chart-wrapper .fill-1-1-1 {
    fill: #ec7a08 !important;
}

:root .cds--cc--tooltip .tooltip-1-1-1 {
    background-color: #ec7a08 !important;
}
.timeline-container {
  width: 100%;
  display: flex;
  max-width: 100vw;
  align-items: center;
  overflow: auto;

  .timeline-container-inner {
    display: flex;
    align-items: center;
    position: relative;
    gap: 1rem;

    .line {
      position: absolute;
      z-index: 0;
      display: flex;
      flex-direction: row;
      width: calc(100% - 120px);
      margin-left: 60px;
      border-bottom: solid 0.2rem white;
    }

    .timeline-content {
      display: flex;
      min-width: 16rem;
      min-height: 8rem;
      flex-direction: row;
      overflow: auto;
      gap: 1rem;
      padding: 1rem 1rem 1rem 1rem;
      background: var(--cds-layer);

      h3 {
        position: absolute;
        top: 0;
      }
    }

    .timeline-content:last-of-type:not(:only-of-type) {
      .phase-container:last-of-type {
        margin: auto;
        margin-right: 0;
      }
    }
  }
}
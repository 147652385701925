@use '@carbon/react';
@use "@carbon/styles";
@use '@carbon/themes';
@import "https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|IBM+Plex+Sans:400,600&display=swap";
@import "MakeMakeTheme";


html {
  @include themes.theme($theme--colmena);
  min-height: 100%;
  display: flex;
}

body {
  display: flex;
  flex-grow: 1;
}

:root {

  .pointer {
    cursor: pointer;
  }

  .text-center {
    text-align: center;
  }


  .main-container {
    display: flex;
    flex-grow: 1;
    position: relative;
    min-height: 100vh;
  }

  .inner-main-container {
    display: flex;
    flex-grow: 1;
    height: calc(100% - 3rem);
    padding: 0 !important;
    padding-bottom: 2.5rem;
    margin-bottom: 10rem;
  }

  .cds--content {
    background: themes.$background;
  }

  select {
    color: white !important;
  }

  input {
    color: white !important;
  }

  .cds--cc--tooltip .content-box .datapoint-tooltip p {
    color: white !important
  }
  
  .page-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
  }

  .edit-action {
    cursor: pointer;
  }

  .cds--chart-holder.fullscreen .cds--cc--chart-wrapper {
    background: white !important;
  }

  @import "styles-index";
}



.kddPhase {
  //overflow: auto;
  .title {
    .item-name {
      font-style: italic;
    }
  }

  .inner-content {
    display: flex;
    flex-direction: column;

    .inline-form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .cds--form-item {
        width: 100%;
      }

      .action-buttons {
        padding: .5rem 0;
      }

      @media screen and (max-width: 537px) {
        .action-buttons {
          gap: .1rem;
          flex-wrap: wrap;
        }
      }
    }
  }
}

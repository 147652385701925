.kdd {
  //overflow: auto;
  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
  }

  .title {
    .item-name {
      font-style: italic;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;


    .cds--form-item {
      width: 100%;
    }
  }

  .inner-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;

    .inline-form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }


    .swim-line-container {
      padding-top: 1rem;
    }
  }
}

.process {
  //overflow: auto;

  .title {
    .item-name {
      font-style: italic;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;

    .cds--form-item {
      width: 100%;
    }
  }

  .inner-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;

    .inline-form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}

@import "Join/styles-index";
@import "Kdd/styles-index";
@import "Process/styles-index";
@import "Sanitize/styles-index";
@import "SwimLane/index";
@import "SwimLane/Phase/index";
@import "index";

.button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.profile {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 30rem;
  margin: auto;
}

.profile-inner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--cds-layer);
  padding: 1rem;
}

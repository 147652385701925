.swim-lane-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  .new-phase-button {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: fit-content;
  }

  .phases-container {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;

  }
}